import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Content = {
  pageName: "New Page Template",
}

const NewPageTemplate = () => {
  return (
    <Layout>
      <Seo title={Content.pageName} description={Content.description} />
    </Layout>
  )
}

export default NewPageTemplate
